<template>
  <div>
    <!-- table -->
    <!-- :show="isLoading" -->
    <b-overlay
      rounded="sm"
    >
      <b-card class="p-0">
        <vue-good-table
          theme="polar-bear"
          mode="remote"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: true,
            perPage: serverParams.perPage,
            perPageDropdown: [5, 10, 20, 50, 100],
            dropdownAllowAll: false,
          }"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :is-loading.sync="isLoading"
          :total-rows="totalRecords"
          :columns="columns"
          style-class="vgt-table condensed bordered"
          compact-mode
          :rows="all_my_applications"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-selected-rows-change="onSelectionChange"
        >
          <template
            slot="column-filter"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'created_on'"
              class="mt-2"
            >
              <flat-pickr
                v-model="created_on_filter"
                :config="configPicker"
                class="vgt-input"
                placeholder="Filter Applied On"
                @input="(value) => updateFilters(props.column.field, value)"
              />
            </span>

            <span
              v-else-if="props.column.field === 'interview_date_time'"
              class="mt-2"
            >
              <flat-pickr
                v-model="interview_date_time_filter"
                :config="configPicker"
                class="vgt-input"
                placeholder="Filter Interview Date and Time"
                @input="(value) => updateFilters(props.column.field, value)"
              />
            </span>
          </template>

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Status -->
            <span v-if="props.column.field === 'interview_date_time'">
              <div
                class="d-flex flex-wrap justify-content-start"
                style="margin-top: 0rem; gap: 0 0.5rem;"
              >
                <p class="p-0 m-0 mb-1">{{ props.row.interview_date_time ? formatDate(props.row.interview_date_time): '' }}</p>
                <template
                  v-if="props.row.interview_request_status === 'accepted'"
                >
                  <b-badge :variant="'success'" size="sm">
                    Accepted
                  </b-badge>
                </template>
                <template
                  v-if="props.row.interview_request_status === 'sent'"
                >
                  <b-button
                    variant="success"
                    size="sm"
                    @click="processInterviewRequest(props.row.id)"
                  >
                    Accept
                  </b-button>
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="processInterviewRequest(props.row.id)"
                  >
                    Reject
                  </b-button>
                </template>
              </div>
              <!-- <b-badge
                :variant="
                  cleanedInterviewDate(props.row)[0] ? 'success' : 'danger'
                "
              >
                {{
                  cleanedInterviewDate(props.row)[1].length > 2
                    ? cleanedInterviewDate(props.row)[1]
                    : 'Not Yet Updated'
                }}
              </b-badge>
              <div
                v-if="cleanedInterviewDate(props.row)[1].length > 10"
                class="d-flex justify-content-center"
              >
                <b-badge
                  style="margin: 10px"
                  variant="success"
                  class=""
                >Accept</b-badge>
                <b-badge
                  style="margin: 10px"
                  variant="danger"
                  class=""
                >Reject</b-badge>
                <b-badge
                  style="margin: 10px"
                  variant=""
                  class=""
                >Counter Propose</b-badge>
              </div> -->
            </span>

            <span
              v-else-if="
                props.column.field === 'interview_status'
              "
            >
              <b-badge variant="info">
                {{
                  props.row.interview_status.length > 3
                    ? props.row.interview_status
                    : 'Not Yet Updated'
                }}
              </b-badge>
            </span>

            <span v-else-if="props.column.field === 'actions'">
              <div
                class="d-flex flex-row justify-content-center"
                style="gap: 0.5rem;"
              >
                <feather-icon

                  v-b-tooltip.hover.top="
                    `View Job Details`
                  "
                  class="cursor-pointer"
                  icon="CornerUpRightIcon"
                  size="24"
                  @click="openJobPage(props.row)"
                />
                <feather-icon
                  v-if="props.row.application_status !== 'Candidate Withdrew'"
                  v-b-tooltip.hover.top="
                    `Remove Application`
                  "
                  class="text-danger cursor-pointer"
                  icon="TrashIcon"
                  size="24"
                  @click="showDelete(props.row)"
                />
              </div>
            </span>

            <!-- Column: Common -->
            <span v-else>

              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <!-- <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pagination.perPage"
                  :options="[5, 10, 20, 50, 100]"
                  class="mx-1"
                />
                <span class="text-nowrap"> of {{ pagination.totalRows }} entries </span>
              </div>
              <div>
                <b-pagination
                  v-model="pagination.currentPage"
                  :value="1"
                  :total-rows="pagination.totalRows"
                  :per-page="pagination.perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template> -->
        </vue-good-table>
      </b-card>
    </b-overlay>
    <!-- <ProfileCompletionForm
      :activate-popup="popupProfileQuestions"
      :hide-header="true"
      @open-completion-form="activateTypeForm"
    /> -->
  </div>
</template>

<script>
import {
  BCard,
  BBadge,
  BOverlay,
  BPagination,
  BFormSelect,
} from 'bootstrap-vue'
import { format } from 'date-fns'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'

import { completionMixins } from '@/components/mixins/CompletionMixins'

export default {
  components: {
    VueGoodTable,
    BCard,
    BPagination,
    BFormSelect,
    BBadge,
    BOverlay,

    flatPickr,
  },
  mixins: [completionMixins],
  data() {
    return {
      selected_applications: [],
      itemsPerPage: 6,
      isMounted: false,
      event_id: null,
      preview_event_id: null,
      preview_job: false,
      all_my_applications: [],
      all_interview_types: {
        speed_interview: 'Virtual Speed Interview',
        virtual: 'Virtual Interview',
        physical: 'In-Person Interview',
        none: 'Not Specified',
      },
      job_source_types: {
        upload: 'Uploaded',
        curated: 'Suggested By The System',
      },

      pageLength: 10,
      dir: false,

      columns: [
        // {
        //   label: 'Event Name',
        //   field: 'event_details.event_name',
        //   width: '200px',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: '',
        //     trigger: 'enter',
        //   },
        // },
        {
          label: 'Job Position',
          field: 'position',
          width: '24ch',
          filterOptions: {
            enabled: true,
            placeholder: '',
            trigger: 'enter',
          },
        },
        {
          label: 'Employer Name',
          field: 'company_name',
          width: '20ch',
          filterOptions: {
            enabled: true,
            placeholder: '',
            trigger: 'enter',
          },
        },
        {
          label: 'Applied On',
          field: 'created_on',
          width: '13ch',
          type: 'date',
          dateInputFormat: 'do MMM yyyy HH:mm', // expects 2018-03-16
          dateOutputFormat: 'do MMM yyyy HH:mm', // outputs Mar 16th 2018
          tdClass: 'text-left',
          thClass: 'text-left',
          filterOptions: {
            customFilter: true,
            enabled: true,
            placeholder: '',
            trigger: 'enter',
          },
        },
        {
          label: 'Interview Date And Time',
          field: 'interview_date_time',
          width: '19ch',
          type: 'date',
          dateInputFormat: 'do MMM yyyy HH:mm', // expects 2018-03-16
          dateOutputFormat: 'do MMM yyyy HH:mm', // outputs Mar 16th 2018
          tdClass: 'text-left',
          thClass: 'text-left',
          filterOptions: {
            customFilter: true,
            enabled: true,
            placeholder: '',
            trigger: 'enter',
          },
        },

        {
          label: 'Application Status',
          field: 'application_status',
          width: '15ch',
          filterOptions: {
            enabled: true,
            placeholder: '',
            trigger: 'enter',
          },
        },
        {
          label: 'Application Type',
          field: 'application_type',
          width: '15ch',
          filterOptions: {
            enabled: true,
            placeholder: '',
            trigger: 'enter',
          },
        },
        {
          label: 'Interview Details',
          field: 'interview_note',
          width: '15ch',
          filterOptions: {
            enabled: true,
            placeholder: '',
            trigger: 'enter',
          },
        },

        {
          label: 'Actions',
          field: 'actions',
          width: '7ch',
          sortable: false,
          filterOptions: {
            enabled: false,
            placeholder: '',
          },
        },
      ],
      rows: [],
      searchTerm: '',

      isLoading: false,

      // vue-good-table
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 5,
      },
      configPicker: {
        altInput: true,
        enableTime: false,
      },
      configTimePicker: {
        altInput: true,
        noCalendar: true,
        enableTime: true,
      },
      created_on_filter: null,
      interview_date_time_filter: null,

      exportingCSV: false,
    }
  },

  computed: {
    currentApplicationsPage() {
      if (this.isMounted) {
        if (this.$refs.participating_table) {
          return this.$refs.participating_table.currentx
        }
        return 1
      }
      return 0
    },

    queriedApplications() {
      return this.$refs.participating_table
        ? this.$refs.participating_table.queriedResults.length
        : this.all_my_applications.length
    },
  },
  watch: {
  },
  mounted() {
    try {
      const next = this.$children[0].$refs.btnclose
      next.$el.addEventListener('click', this.onClickCloseButton, false)
    } catch (error) {}

    // const incompleteProfile = this.checkProfileCompletion()

    // if (incompleteProfile) {
    //   this.popupProfileQuestions = true
    // }

    this.isMounted = true

    this.getMyApplications()

    // this.populateMockData()
  },

  methods: {
    populateMockData() {
      this.all_my_applications = [
        {
          event_details: {
            position: 'UX/UI Designer',
            company: 'HEADHUNTERS HQ PTE. LTD.',
          },
          created_on: '3rd Mar 2023 13:03',
          interview_date_time: '13th Apr 2023 13:05',
          interview_status: {
            interview_status: 'Accepted',
            application_status: 'Hired',
          },
          interview_details: 'Interview will be conducted with Google Meet, We have sent the link to your email',
          application_type: 'Applied',
        },
        {
          event_details: {
            position: 'UX/UI Designer',
            company: 'MULTITUDEX PTE. LTD.',
          },
          created_on: '4th Mar 2023 13:03',
          interview_date_time: '1st Jul 2023 15:10',
          interview_status: {
            interview_status: 'Pending',
            application_status: 'To Interview',
          },
          interview_details: 'Zoom will be used for the interview, We will send the link to your email 30 minutes prior to the interview session ',
          application_type: 'Suggested',
        },
      ]
    },
    formatDate(date_time) {
      return format(new Date(date_time), 'do LLL, yyyy HH:mm')
    },
    processInterviewRequest(application_id) {

    },
    openJobPage(item) {
      const route = this.$router.resolve({ path: `/jobs/${item.job_id}` })
      // let route = this.$router.resolve('/link/to/page'); // This also works.
      window.open(route.href, '_blank')
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
      this.getMyApplications()
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
    },
    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field
        const sortDetails = { }
        sortDetails[fieldName] = params[0].type
        this.updateParams({ sort: sortDetails })
      }
    },
    onSelectionChange(params) {
      this.selectedRows = params.selectedRows
    },
    onColumnFilter(params) {
      this.serverParams.page = 1
      this.updateParams(params)
    },
    updateFilters(field, value) {
      this.serverParams.page = 1
      this.serverParams.columnFilters[field] = value
      this.updateParams(this.serverParams.columnFilters)
    },

    cleanedInterviewStatus(details) {
      if (details.interview_status.application_status.length > 0) {
        return [true, details.interview_status.application_status]
      }

      return [null, 'Pending Interview']
    },
    cleanedInterviewDate(details) {
      const int_date_time = details.interview_date_time
      if (int_date_time.date.length > 0) {
        return [
          true,
          `${int_date_time.date} (${int_date_time.start_time} - ${int_date_time.end_time})`,
        ]
      }

      return [null, 'Not Set']
    },
    getMyApplications() {
      this.isLoading = true

      // server pagination
      let query = ''
      query = `?params=${JSON.stringify(this.serverParams)}`

      this.$http
        .get(`/api/my-applications${query}`)
        .then(response => {
          this.all_my_applications = response.data.all_applications
          this.totalRecords = response.data.totalRecords

          this.isLoading = false
        })
        .catch(error => {})
    },

    approveJob(job_id, status) {
      this.$http
        .get(`/api/approve-job/${job_id}/${status}`)
        .then(response => {
          const { success } = response.data

          if (success) {
            const idx = this.all_my_applications.findIndex(
              x => x._id === job_id,
            )

            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Job Approval',
                  icon: 'AlertCircleIcon',
                  text: response.data.message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
            this.all_my_applications.splice(idx, 1)
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Job Approval',
                  icon: 'AlertCircleIcon',
                  text: response.data.message,
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(error => {})
    },
    deleteJob(event) {
      this.isLoading = true

      this.$http
        .delete(`/api/applications/${event.id}`, {})
        .then(response => {
          this.getMyApplications()

          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Account operations',
                icon: 'CheckCircleIcon',
                text: 'Application withdrawn successfully',
                variant: 'success',
                position: 'center',
              },
            },
            { timeout: this.$longestTimeout },
          )

          this.isLoading = false
        })
        .catch(error => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Account operations',
                icon: 'AlertCircleIcon',
                text: 'Unable to withdraw application, please try again later',
                variant: 'danger',
                position: 'center',
              },
            },
            { timeout: this.$longestTimeout },
          )

          this.isLoading = false
        })
    },
    showDelete(event) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to withdraw from "${event.position}"`,
          {
            title: 'Confirm application withdrawal',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Withdraw',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.deleteJob(event)
          }
        })
    },
  },
}
</script>
<style lang="scss" scoped>

.card-body {
  padding: .5rem;
}
</style>
